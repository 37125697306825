import { ArchivedIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/ArchivedIcon";
import { CertificatesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/CertificatesIcon";
import { GroupsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/GroupsIcon";
import { ModulesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/ModulesIcon";
import { NexusIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/NexusIcon";
import { PublishedIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/PublishedIcon";
import { QuestionBankIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/QuestionBankIcon";
import { IOptionsHook, ISubSection } from "pages/[tenant]/console/types";

export const useGroupAndContentOptions = (): IOptionsHook => {
  const sectionLabel = "Recursos & conteúdos";
  const groupsAndContentSubSections: ISubSection[] = [
    {
      label: "Grupos",
      options: [
        {
          id: 1,
          label: "Todos os grupos",
          path: "/console/groups",
          icon: <GroupsIcon />,
        },
        {
          id: 2,
          label: "Publicados",
          path: "/console/groups/published",
          icon: <PublishedIcon />,
        },
        {
          id: 3,
          label: "Arquivados",
          path: "/console/groups/archived",
          icon: <ArchivedIcon />,
        },
        {
          id: 4,
          label: "Vínculos",
          path: "/console/groups/bindings",
          icon: <NexusIcon />,
        },
      ],
    },
    {
      label: "Recursos",
      options: [
        {
          id: 20,
          label: "Módulos",
          path: "/console/modules",
          icon: <ModulesIcon />,
        },
        {
          id: 21,
          label: "Certificados",
          path: "/console/groups/certificates",
          icon: <CertificatesIcon />,
        },
        {
          id: 22,
          label: "Banco de Questões",
          path: "/console/questionbank",
          icon: <QuestionBankIcon />,
        },
      ],
    },
  ];

  return {
    subSections: groupsAndContentSubSections,
    label: sectionLabel,
  };
};
