import { UserIcon } from "pages/[tenant]/console/assets/icons/UserIcon";
import { IOption, IOptionsHook } from "pages/[tenant]/console/types";

export const usePeopleOptions = (): IOptionsHook => {
  const sectionLabel = "Pessoas";
  const options: IOption[] = [
    {
      id: 1,
      label: "Todos os usuários",
      path: "/console/users",
      icon: <UserIcon />,
    },
    {
      id: 2,
      label: "Usuários ativos",
      path: "/console/users/active",
      icon: <UserIcon />,
    },
    {
      id: 3,
      label: "Usuários desativados",
      path: "/console/users/disabled",
      icon: <UserIcon />,
    },
  ];

  return {
    label: sectionLabel,
    options,
  };
};
