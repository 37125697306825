import React from "react";

export const ChatIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6654 9.3335H10.9987C11.55 9.3335 11.9987 8.88483 11.9987 8.3335V2.3335C11.9987 1.78216 11.55 1.3335 10.9987 1.3335H2.33203C1.7807 1.3335 1.33203 1.78216 1.33203 2.3335V12.0002L4.88736 9.3335H10.6654ZM4.44336 8.00016L2.66536 9.3335V2.66683H10.6654V8.00016H4.44336Z"
        fill="currentColor"
      />
      <path
        d="M13.6654 5.3335H13.332V9.33416C13.332 10.0675 12.7367 10.6628 12.0054 10.6668H5.33203V11.0002C5.33203 11.5515 5.7807 12.0002 6.33203 12.0002H11.11L14.6654 14.6668V6.3335C14.6654 5.78216 14.2167 5.3335 13.6654 5.3335Z"
        fill="currentColor"
      />
    </svg>
  );
};
