import styled from "styled-components";

export const MyGroupsListContainer = styled.ul`
  margin: 0;
  list-style: none;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
