import React from "react";

import Link from "next/link";

import { RocketIcon } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/icons/RocketIcon";

import { TopPageFlagContainer } from "./styles";

interface ITopPageFlagProps {
  children: string;
  href: string;
}

export const TopPageFlag = ({ children, href }: ITopPageFlagProps) => {
  return (
    <Link href={href}>
      <TopPageFlagContainer>
        <RocketIcon /> {children}
      </TopPageFlagContainer>
    </Link>
  );
};
