import { useRouter } from "next/router";

import { pagesToHideConsolePageSideBar } from "pages/[tenant]/console/utils/layoutUtils";

export const consolePageMobileBreakpoint = 850;

export const useConsolePageConfig = () => {
  const { pathname } = useRouter();

  const isInConsolePage = pathname?.includes("/console");
  const isInConsoleInternalPage =
    pathname.split("/").reverse()[0] !== "console" && isInConsolePage;
  const shouldHideSideBar = pagesToHideConsolePageSideBar.find((page) =>
    pathname.includes(page),
  );
  const isInNotHavePermissionPage = pathname?.includes("/401");
  const shouldShowConsolePageSideBar =
    isInConsolePage && !shouldHideSideBar && !isInNotHavePermissionPage;

  return {
    shouldShowConsolePageSideBar,
    isInConsolePage,
    isInConsoleInternalPage,
  };
};
