import React, { MouseEvent } from "react";

import { MenusList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MenusList";
import { MyGroupsList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MyGroupsList";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";

import { Divider, Overlay, SideMenuContainer } from "./styles";

export const SideMenu = () => {
  const { closeSideMenu, sideMenuIsOpen } = useTopbarContext();

  const handleOutsideClick = ({ target }: MouseEvent<HTMLDivElement>) => {
    const clickedElement = target as HTMLDivElement;
    const userClickedInOverlay = [...clickedElement.classList].includes(
      Overlay.styledComponentId,
    );

    if (userClickedInOverlay) closeSideMenu();
  };

  return (
    <Overlay show={sideMenuIsOpen} onClick={handleOutsideClick}>
      <SideMenuContainer show={sideMenuIsOpen}>
        <Divider />

        <MenusList triggerOnLinkClick={closeSideMenu} />

        <MyGroupsList triggerOnLinkClick={closeSideMenu} />
      </SideMenuContainer>
    </Overlay>
  );
};
