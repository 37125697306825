import { CertificatesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/CertificatesIcon";
import { IOption, IOptionsHook } from "pages/[tenant]/console/types";

export const useGamificationOptions = (): IOptionsHook => {
  const sectionLabel = "Gamificação";
  const options: IOption[] = [
    {
      id: 1,
      label: "Definições",
      path: "/console/teste",
      icon: <CertificatesIcon />,
    },
    {
      id: 2,
      label: "Desafios",
      path: "/console/teste",
      icon: <CertificatesIcon />,
    },
    {
      id: 3,
      label: "Missões",
      path: "/console/teste",
      icon: <CertificatesIcon />,
    },
    {
      id: 4,
      label: "Níveis",
      path: "/console/teste",
      icon: <CertificatesIcon />,
    },
    {
      id: 5,
      label: "Ranking",
      path: "/console/teste",
      icon: <CertificatesIcon />,
    },
    {
      id: 6,
      label: "Rewards",
      path: "/console/teste",
      icon: <CertificatesIcon />,
    },
  ];

  return {
    label: sectionLabel,
    options,
  };
};
