const buffer = require("buffer").Buffer;

export async function getPlatformTenant(hostname, clientIp?) {

  const isLocalAPI = /loadtest.ensinio.local/.test(hostname);

  if (!isLocalAPI) {
    const QAPlataforms = ["qa1", "qa2", "qa3", "qa4", "qa5", "qa6"];
    const isDevEnv =
      /ngrok|loca.lt|localhost|dev.ensinio.com|ensinio-next.vercel.app/.test(
        hostname,
      );

    const hostname_uuid = hostname?.replace(".ensinio.com", "");
    const tenancyURLBase64 = buffer
      .from(
        isDevEnv
          ? "dev.ensinio.com"
          : hostname?.replace(".vercel.app", ".ensinio.com"),
      )
      .toString("base64");

    const endpoint = isDevEnv
      ? "ensiniodev.com"
      : QAPlataforms.includes(hostname_uuid)
      ? `ensinio${hostname_uuid}.click`
      : "ensinio.cloud";

    const response = await fetch(
      `https://${endpoint}/api/v1/ten?host=${tenancyURLBase64}`,
      {
        method: "POST",
        headers: new Headers({
          "App-Name": "student-area",
          "Client-Type": "web",
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Requested-With": "XMLHttpRequest",
          ...(clientIp && { "X-Forwarded-For": clientIp }),
        }),
      },
    );

    const tenancy = await response?.json();

    const uuid = isDevEnv
      ? "dev"
      : QAPlataforms.includes(hostname_uuid)
      ? hostname_uuid
      : tenancy?.data?.uuid;

    return { ...tenancy, uuid };
  }

  const tenancyURL = hostname?.replace(".ensinio.local", ".ensinio.com");
  const tenancyURLBase64 = buffer.from(tenancyURL).toString("base64");

  const response = await fetch(
    `http://${hostname}/api/v1/ten?host=${tenancyURLBase64}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Requested-With": "XMLHttpRequest",
        ...(clientIp && { "X-Forwarded-For": clientIp }),
      }),
    },
  );

  const tenancy = await response?.json();

  const uuid = tenancy?.data?.uuid;

  return { ...tenancy, uuid };
}
