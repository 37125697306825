import { createContext, useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";

import { useConsoleSideBarActiveItemsIndicator } from "pages/[tenant]/console/hooks/useConsoleSideBarActiveItemsIndicator";
import { useSideBarSections } from "pages/[tenant]/console/hooks/useSideBarSections";
import {
  IConsolePageContext,
  IOption,
  IOptionsList,
  ISection,
} from "pages/[tenant]/console/types";

export const ConsolePageContext = createContext<IConsolePageContext>(
  {} as IConsolePageContext,
);

export const ConsolePageContextProvider = ({ children }) => {
  const { sectionInfo } = useSideBarSections();
  const optionsList = useMemo<IOptionsList>(
    () =>
      Object.entries(sectionInfo).reduce(
        (acc, [_, option]) => ({
          ...acc,
          [option.label]: {
            options: option.options,
            subSections: option?.subSections,
          },
        }),
        {},
      ),
    [sectionInfo],
  );

  const {
    setInfoToMoveActiveSectionIndicator,
    activeSectionPosition,
    activeOptionPosition,
    setInfoToMoveActiveOptionIndicator,
  } = useConsoleSideBarActiveItemsIndicator();

  const { asPath } = useRouter();
  const [activeOptionPathInRealTime, setActiveOptionPathInRealTime] =
    useState(asPath);
  const [activeSectionLabel, setActiveSectionLabel] = useState("");
  useState(asPath);

  const [isMobileSectionsDropdownOpen, setIsMobileSectionsDropdownOpen] =
    useState(false);
  const [
    isMobileSectionOptionsDropdownOpen,
    setIsMobileSectionOptionsDropdownOpen,
  ] = useState(false);

  const openMobileSectionsDropdown = () => {
    if (isMobileSectionOptionsDropdownOpen) closeMobileSectionOptionsDropdown();
    setIsMobileSectionsDropdownOpen(true);
  };

  const closeMobileSectionsDropdown = () =>
    setIsMobileSectionsDropdownOpen(false);

  const openMobileSectionOptionsDropdown = () => {
    if (isMobileSectionsDropdownOpen) closeMobileSectionsDropdown();
    setIsMobileSectionOptionsDropdownOpen(true);
  };

  const closeMobileSectionOptionsDropdown = () =>
    setIsMobileSectionOptionsDropdownOpen(false);

  const changeActiveSection = (label: string) => setActiveSectionLabel(label);
  const clearActiveSection = () => setActiveSectionLabel("");

  const getActiveOptionInView = (currentSectionOptions: ISection): IOption => {
    const currentSectionHasOptions =
      currentSectionOptions?.options || currentSectionOptions?.subSections;
    if (!currentSectionHasOptions) return;

    const { options, subSections } = currentSectionOptions;
    const activeOption =
      options?.find((option) => option.path === asPath) ||
      subSections
        ?.find((subSection) =>
          subSection?.options?.find((option) => option.path === asPath),
        )
        .options?.find((option) => option.path === asPath);

    return activeOption;
  };

  const getCurrentSectionInfoByCurrentPath = (currentPath: string) => {
    const [currentSectionName, currentSectionOptions] = Object.entries(
      optionsList,
    ).find(
      ([_, value]) =>
        value.options?.some((option) => option.path === currentPath) ||
        value.subSections?.some((option) =>
          option.options?.some((option) => option.path === currentPath),
        ),
    ) || [""];

    return {
      currentSectionName,
      currentSectionOptions,
    };
  };

  useEffect(() => {
    if (!optionsList) return;

    const { currentSectionName } = getCurrentSectionInfoByCurrentPath(asPath);
    changeActiveSection(currentSectionName);
    setActiveOptionPathInRealTime(asPath);
  }, [asPath]);

  const handleChangeActiveSection = ({ currentTarget }) => {
    const sectionLabel = currentTarget?.getAttribute("data-label");

    changeActiveSection(sectionLabel);
    setInfoToMoveActiveSectionIndicator(currentTarget);
  };

  const clearOptionPathInRealTime = () => setActiveOptionPathInRealTime("");

  return (
    <ConsolePageContext.Provider
      value={{
        optionsList,
        changeActiveSection,
        activeSectionLabel,
        clearActiveSection,
        getCurrentSectionInfoByCurrentPath,
        sectionInfo,
        setInfoToMoveActiveSectionIndicator,
        handleChangeActiveSection,
        activeOptionPathInRealTime,
        setActiveOptionPathInRealTime,
        activeSectionPosition,
        getActiveOptionInView,
        isMobileSectionsDropdownOpen,
        isMobileSectionOptionsDropdownOpen,
        openMobileSectionsDropdown,
        closeMobileSectionsDropdown,
        openMobileSectionOptionsDropdown,
        closeMobileSectionOptionsDropdown,
        activeOptionPosition,
        setInfoToMoveActiveOptionIndicator,
        clearOptionPathInRealTime,
      }}
    >
      {children}
    </ConsolePageContext.Provider>
  );
};
