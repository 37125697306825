import { useContext, useLayoutEffect } from "react";

import { useRouter } from "next/router";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";

import { CollectionProvider } from "modules/Collection/context";
import { GlobalSearchProvider } from "modules/GlobalSearch/context";
import { OrdersProvider } from "modules/Order/context";

import { ConsolePageSideBar } from "pages/[tenant]/console/components/organisms/ConsolePageSideBar";
import { ConsoleLayout } from "pages/[tenant]/console/components/templates/ConsoleLayout";
import { ConsolePageContextProvider } from "pages/[tenant]/console/context/ConsolePage";
import { useConsolePageConfig } from "pages/[tenant]/console/hooks/useConsolePageConfig";
import GroupContextProvider from "pages/[tenant]/g/context/GroupsContext";

import EnsinioBrand from "shared/components/atoms/EnsinioBrand";
import PageLoader from "shared/components/atoms/PageLoader";
import ToastNotification from "shared/components/molecules/ToastNotification";
import AuthModal from "shared/components/organisms/AuthModal";
import BackToTopFooter from "shared/components/organisms/BackToTopFooter";
import BrowserNotSupported from "shared/components/organisms/BrowserNotSupported";
import DenounceModal from "shared/components/organisms/Denounce";
import ExclusivePage from "shared/components/organisms/ExclusivePage";
import MobilePurchaseDisableModal from "shared/components/organisms/MobilePurchaseDisableModal";
import OnlyEnrolledUsersPermission from "shared/components/organisms/OnlyEnrolledUsersPermission";
import PurchaseOptionsModal from "shared/components/organisms/PurchaseOptionsModal";
import { NewTopBar } from "shared/providers/Layout/components/NewTopBar";
import { MobileFooterNavigation } from "shared/providers/Layout/components/NewTopBar/components/molecules/MobileFooterNavigation";
import { TopbarContextProvider } from "shared/providers/Layout/context/TopbarContext";
import GlobalContext from "shared/providers/context/GlobalContext";
import { MenusContextProvider } from "shared/providers/context/MenusContext";
import { NewGlobalSearchContextProvider } from "shared/providers/context/NewGlobalSearchContext";
import Theme from "shared/styles/Theme";
import { useFeature } from "shared/utils/hooks/useFeature";
import useNextLoadingProgress from "shared/utils/hooks/useNextLoadingProgress";

import AuthContext from "../context/AuthContext";
import LayoutContext from "./context";
import { AppLayoutContainer, AppLayoutContent, Layout } from "./styles";
import ChromeCastWarnModal from "shared/components/organisms/ChromeCastWarnModal";

const AppLayout = ({ children }) => {
  const router = useRouter();
  const asPath = router?.asPath;
  const {
    shouldShowConsolePageSideBar,
    isInConsolePage,
    isInConsoleInternalPage,
  } = useConsolePageConfig();

  const { state } = useContext(GlobalContext);
  const { loggedUser, isLoadingUser } = useContext(AuthContext);
  const platformIsPrivate = state.tenancy?.settings?.site?.privatePlatform;

  const isSideMenuEnabled = useFeature("f_side_menu");

  const { setBrowserNotSuportObserver, sidebarIsRendered } =
    useContext(LayoutContext);
  const pageIsLoading = useNextLoadingProgress();

  const urlValidateCertificate = asPath?.split("/")[1];

  const isVisibleEnsinioBrand =
    !asPath?.includes("/authentication") &&
    !asPath?.includes("/payment") &&
    urlValidateCertificate !== "certificate";

  const hasPadding =
    !asPath.includes("/social") &&
    !asPath.includes("/payment") &&
    !isInConsolePage;
  const isPaymentRoute = asPath.includes("/payment");

  const currentPath = asPath.split("/")[1];

  useLayoutEffect(() => {
    if (typeof IntersectionObserver !== "function") {
      setBrowserNotSuportObserver();
    }
  }, [setBrowserNotSuportObserver]);

  return (
    <ThemeProvider theme={Theme(state.theme.value)}>
      <GlobalSearchProvider>
        <GroupContextProvider>
          <NewGlobalSearchContextProvider>
            <MenusContextProvider>
              <AppLayoutContainer>
                <div id="modal-root" />
                <AuthModal />
                <OnlyEnrolledUsersPermission />
                <DenounceModal />
                <ToastContainer hideProgressBar={true} />
                <BrowserNotSupported />
                <MobilePurchaseDisableModal />
                <ChromeCastWarnModal />

                <Layout className="app-layout">
                  <Layout $isInConsolePage={isInConsolePage}>
                    <TopbarContextProvider>
                      <NewTopBar />
                    </TopbarContextProvider>
                    <OnlyEnrolledUsersPermission />

                    {!isLoadingUser &&
                    !loggedUser &&
                    platformIsPrivate === "1" &&
                    !asPath?.includes("/first-access") &&
                    !asPath?.includes("/authentication") &&
                    !asPath?.includes("/reset") &&
                    !asPath?.includes("/payment") ? (
                      <ExclusivePage />
                    ) : (
                      <AppLayoutContent
                        $hasPadding={hasPadding}
                        $isPaymentRoute={isPaymentRoute}
                        $sidebarIsRendered={
                          isSideMenuEnabled && sidebarIsRendered
                        }
                        $shouldExpandConsolePageGrid={isInConsoleInternalPage}
                        $isInConsolePage={isInConsolePage}
                        $shouldShowConsolePageSideBar={
                          shouldShowConsolePageSideBar
                        }
                      >
                        <CollectionProvider>
                          <OrdersProvider>
                            <div className="main-content-wrapper">
                              {isInConsolePage ? (
                                <ConsolePageContextProvider>
                                  {shouldShowConsolePageSideBar && (
                                    <ConsolePageSideBar />
                                  )}
                                  {pageIsLoading ? (
                                    <PageLoader />
                                  ) : (
                                    <ConsoleLayout>{children}</ConsoleLayout>
                                  )}
                                </ConsolePageContextProvider>
                              ) : pageIsLoading ? (
                                <PageLoader />
                              ) : (
                                children
                              )}
                            </div>
                            <BackToTopFooter />
                          </OrdersProvider>
                        </CollectionProvider>

                        <ToastNotification />
                        {isVisibleEnsinioBrand && <EnsinioBrand />}
                      </AppLayoutContent>
                    )}

                    <TopbarContextProvider>
                      <MobileFooterNavigation />
                    </TopbarContextProvider>
                  </Layout>

                  <PurchaseOptionsModal />

                  {!loggedUser &&
                    !asPath?.includes("/authentication") &&
                    !asPath?.includes("/payment")}
                </Layout>
              </AppLayoutContainer>
            </MenusContextProvider>
          </NewGlobalSearchContextProvider>
        </GroupContextProvider>
      </GlobalSearchProvider>
    </ThemeProvider>
  );
};

export default AppLayout;
