import React from "react";

import Link from "next/link";

import { RocketIcon } from "pages/[tenant]/console/components/templates/ConsoleLayout/assets/icons/RocketIcon";

import { ActionButtonContainer } from "./styles";

interface IActionButtonProps {
  children: string;
  href: string;
}

export const ActionButton = ({ children, href }: IActionButtonProps) => {
  return (
    <Link href={href}>
      <ActionButtonContainer>
        <RocketIcon /> {children}
      </ActionButtonContainer>
    </Link>
  );
};
