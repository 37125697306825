import axios from "axios";

import { getMode } from "shared/infra/services/api";

const slack = axios.create();

const sendLogToSlack = (logText) => {
  const isDevelopment = getMode();

  const payload = { text: logText };

  if (isDevelopment) {
    if (window.location.hostname !== "localhost") {
      slack.post(
        "https://hooks.slack.com/services/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",
        JSON.stringify(payload),
      );
    }

    return;
  }

  slack.post(
    "https://hooks.slack.com/services/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",
    JSON.stringify(payload),
  );
};

export default sendLogToSlack;
