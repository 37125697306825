import styled from "styled-components";

interface IContainerProps {
  squareFormat?: boolean;
  smallFormat?: boolean;
}

export const SimpleUserInitialsNameContainer = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme?.colors?.primary}!important;
  border-radius: 100%;
  border: none;

  width: ${({ smallFormat }) => (smallFormat ? "32px" : "42px")};
  height: ${({ smallFormat }) => (smallFormat ? "32px" : "42px")};

  margin: 0;

  * {
    line-height: 0;
  }

  h1 {
    margin: 0;
    color: ${({ theme }) => theme.colors.textMasterColor} !important;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: ${({ smallFormat }) => (smallFormat ? "12px" : "18px")};

    height: max-content;
    width: max-content;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
