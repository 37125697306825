import React from "react";

export const DiscutionsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6787 2.5415L10.1547 5.3315H6.844L7.32133 2.78817L6.012 2.5415L5.488 5.3315H2.66667V6.66484H5.238L4.73733 9.3315H2V10.6648H4.48733L4.01133 13.2015L5.32133 13.4475L5.844 10.6648H9.15467L8.67867 13.2015L9.98867 13.4475L10.5107 10.6648H13.3333V9.3315H10.7607L11.2613 6.66484H14V5.3315H11.5113L11.9887 2.78817L10.6787 2.5415ZM9.404 9.3315H6.094L6.59467 6.66484H9.90533L9.404 9.3315Z"
        fill="currentColor"
      />
    </svg>
  );
};
