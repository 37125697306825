import { Layout as LayoutAnt } from "antd";
import styled, { css, keyframes } from "styled-components";

import { BillboardPageContainer } from "pages/[tenant]/browse/components/BillboardPage/styles";
import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";
import { GroupPageIndexContainer } from "pages/[tenant]/g/components/GroupPage/styles";
import { SearchPageContainer } from "pages/[tenant]/search/styles";

export const AppLayoutContainer = styled.div`
  height: 100%;

  .app-layout {
    height: 100%;
  }

  :has(${BillboardPageContainer}) {
    height: initial;
  }

  .ant-btn-primary,
  .ant-btn-primary:is(:hover, :focus) {
    background-color: ${({ theme }) => theme.colors.primary} !important;
    color: ${({ theme }) => theme.colors.btnTextColor} !important;
    border: none;

    span {
      color: ${({ theme }) => theme.colors.btnTextColor} !important;
    }
  }
  && {
    div.sweet-alert {
      span {
        z-index: 5 !important;
      }

      h3,
      p {
        color: ${({ theme }) => theme.colors.textColor} !important;
        text-shadow: 1px 1px 25px rgba(0, 0, 0, 0.8) !important;
      }

      &,
      div {
        background-color: ${(props) =>
          props.theme.colors.modalBackgroundColor} !important;
      }

      a {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
`;

interface IContentProps {
  $hasPadding: boolean;
  $isPaymentRoute: boolean;
  $sidebarIsRendered: boolean;
  $isInConsolePage: boolean;
  $shouldShowConsolePageSideBar: boolean;
  $shouldExpandConsolePageGrid: boolean;
}

const loadingAnimation = keyframes`
from {
  transform: rotate(0);
}

to {
  
  transform: rotate(360deg);
}
`;

export const AppLayoutContent = styled(LayoutAnt.Content)<IContentProps>`
  height: 100%;

  background: ${({ theme }) =>
    theme.mode !== "dark" ? theme.colors.backgroundColor : "#181818"};

  padding: ${(props) =>
    props.$isPaymentRoute
      ? "4rem 0 0"
      : !props.$sidebarIsRendered ||
        props.theme.settings?.themeMainMenu === "on_top_bar"
      ? "4rem 0px 0px 0px"
      : "4rem 0px 0px 64px"};

  :has(${SearchPageContainer}) {
    padding: 4rem 0;

    background-color: ${({ theme }) =>
      theme.mode === "dark" ? "#050505" : "#f2f2f2"};

    @media (max-width: 850px) {
      padding-top: 0;
    }
  }

  ${({
    $isInConsolePage,
    $shouldShowConsolePageSideBar,
    $shouldExpandConsolePageGrid,
    theme,
  }) =>
    $isInConsolePage &&
    css`
      padding: 0 !important;
      overflow-y: scroll;

      div.main-content-wrapper {
        display: grid;
        background-color: ${theme.console.backgroundColor};

        position: sticky;
        top: 0;

        ${$shouldShowConsolePageSideBar &&
        css`
          grid-template-columns: ${$shouldExpandConsolePageGrid
            ? "60px 230px 1fr"
            : "60px 1fr"};
        `}

        .loader {
          animation: ${loadingAnimation} 0.7s linear infinite;
        }

        @media (max-width: ${consolePageMobileBreakpoint}px) {
          ${$shouldShowConsolePageSideBar &&
          css`
            grid-template-columns: 1fr;

            padding: 6.5rem 0 4rem 0 !important;
          `}
        }
      }
    `}

  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  :has(${GroupPageIndexContainer}) {
    overflow-x: initial;
  }

  @media (max-width: 850px) {
    margin-top: 0px;
  }

  .main-content-wrapper {
    flex: 1 1;

    ${(props) =>
      props.$hasPadding
        ? css`
            padding: 32px 32px 0;
          `
        : css`
            padding: 0 !important;
          `}

    /* background: ${(props) =>
      props.theme.colors.backgroundColor} !important; */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: ${({ theme }) => theme.colors.textColor};
    }

    @media screen and (max-width: 600px) {
      ${(props) =>
        props.$hasPadding
          ? css`
              padding: 32px 16px;
            `
          : css`
              padding: 0;
            `}
    }
  }

  @media screen and (max-width: 990px) {
    ${(props) =>
      props.$isPaymentRoute
        ? css`
            padding: 3rem 0 0;
          `
        : css`
            padding: 4rem 0px 0px;
          `}
  }
`;

interface ILayoutProps {
  $isInConsolePage?: boolean;
}

export const Layout = styled(LayoutAnt)<ILayoutProps>`
  @media (max-width: 768px) {
    ${({ $isInConsolePage }) =>
      !$isInConsolePage &&
      css`
        margin-bottom: 4rem;
      `}
  }
`;
