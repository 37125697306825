import { createContext, useState } from "react";

import { NodeWithChildren } from "domhandler";

import useNextLoadingProgress from "shared/utils/hooks/useNextLoadingProgress";

interface ITopbarContext {
  scrollIsOnTop: boolean;
  setScrollIsOnTop: React.Dispatch<React.SetStateAction<boolean>>;
  pageIsLoading: boolean;
  sideMenuIsOpen: boolean;
  footerMobileMenuIsOpen: boolean;
  openSideMenu: () => void;
  closeSideMenu: () => void;
  openFooterMobileMenu: () => void;
  closeFooterMobileMenu: () => void;
  notificationsIsOpen: boolean;
  openNotifications: () => void;
  closeNotifications: () => void;
  searchIsOpen: boolean;
  openSearch: () => void;
  closeSearch: () => void;
}

export const TopbarContext = createContext({} as ITopbarContext);

export const TopbarContextProvider = ({ children }: NodeWithChildren) => {
  const [scrollIsOnTop, setScrollIsOnTop] = useState(false);
  const pageIsLoading = useNextLoadingProgress();

  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const openSideMenu = () => setSideMenuIsOpen(true);
  const closeSideMenu = () => setSideMenuIsOpen(false);

  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const closeSearch = () => setSearchIsOpen(false);
  const openSearch = () => {
    if (notificationsIsOpen) closeNotifications();
    if (footerMobileMenuIsOpen) closeFooterMobileMenu();
    setSearchIsOpen(true);
  };

  const [footerMobileMenuIsOpen, setFooterMobileMenuIsOpen] = useState(false);
  const closeFooterMobileMenu = () => setFooterMobileMenuIsOpen(false);
  const openFooterMobileMenu = () => {
    if (notificationsIsOpen) closeNotifications();
    if (searchIsOpen) closeSearch();
    setFooterMobileMenuIsOpen(true);
  };

  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);
  const closeNotifications = () => setNotificationsIsOpen(false);
  const openNotifications = () => {
    if (footerMobileMenuIsOpen) closeFooterMobileMenu();
    if (searchIsOpen) closeSearch();

    setNotificationsIsOpen(true);
  };

  return (
    <TopbarContext.Provider
      value={{
        scrollIsOnTop,
        setScrollIsOnTop,
        sideMenuIsOpen,
        footerMobileMenuIsOpen,
        openSideMenu,
        closeSideMenu,
        openFooterMobileMenu,
        closeFooterMobileMenu,
        pageIsLoading,
        notificationsIsOpen,
        openNotifications,
        closeNotifications,
        searchIsOpen,
        openSearch,
        closeSearch,
      }}
    >
      {children}
    </TopbarContext.Provider>
  );
};
