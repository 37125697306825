import { createContext, useEffect, useState } from "react";
import cookie from "js-cookie";
import { parseCookies } from "nookies";
import Router, { useRouter } from "next/router";

import api from "shared/infra/services/api";
import emailTokenConfirmation from "shared/infra/services/auth/emailTokenConfirmation";
import {
  firstAccessRequest,
  recoverUserInformation,
  registerRequest,
  registerWithoutPasswordRequest,
  resetPasswordRequest,
  signInRequest,
  updateUserRequest,
} from "shared/infra/services/auth/requests-api";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import useUtm from "shared/utils/hooks/useUtm";

import {
  ICollections,
  IComments,
  IFollowers,
  IFollowing,
  ILastTopics,
} from "./initialStates/user";
import {
  AuthContextData,
  AuthContextProps,
  FirstAccess,
  IUpdateUserProfileInfo,
  RegisterData,
  RegisterWithoutPassword,
  ResetPasswordData,
  SignInCheckoutOnePage,
  SignInData,
  User,
} from "./types/auth-context";

export const signOutAux = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  localStorage.removeItem("cover_image");
  localStorage.removeItem("photo");

  cookie.remove("token");
  cookie.remove("username");

  Router.push("/dashboard");
};

export const setCookies = (parseToken: string, username: string) => {
  cookie.set("token", JSON.stringify(parseToken), {
    expires: 30, // 30 days
  });
  cookie.set("username", JSON.stringify(username), {
    expires: 30, // 30 days
  });
};

const AuthContext = createContext({} as AuthContextProps);

export function AuthContextProvider({ children }: AuthContextData) {
  const router = useRouter();
  const { createUtm } = useUtm();
  const queries = router.query;
  const auth_token: string = String(queries?.auth_token);
  const auth_username: string = String(queries?.auth_username);

  const [user, setUser] = useState<User | null>(null);
  const [comments, setComments] = useState<IComments>({} as IComments);
  const [followers, setFollowers] = useState<IFollowers>({} as IFollowers);
  const [following, setFollowing] = useState<IFollowing>({} as IFollowing);
  const [lastTopics, setLastTopics] = useState<ILastTopics>({} as ILastTopics);
  const [collections, setCollections] = useState<ICollections>(
    {} as ICollections,
  );

  const [loggedUser, setLoggedUser] = useState<User | null>(null);
  const [isProfileOwner, setIsProfileOwner] = useState<boolean>(false);
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(null);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
  const { setState } = useGlobalContext();

  const isAuthenticated = !!loggedUser;

  useEffect(() => {
    if (!loggedUser) {
      setIsLoadingUser(true);
      const { token, username: stringfiedUsername } = parseCookies();

      if (token && stringfiedUsername) {
        const username = JSON.parse(stringfiedUsername || "");
        recoverUserInformation(username, api).then((response) => {
          setLoggedUser(response.data);
        });
      }

      setIsLoadingUser(false);
    }
  }, [api]);

  useEffect(() => {
    if (loggedUser) return;

    if (auth_token && auth_username) {
      recoverUserInformation(auth_username, api).then((response) => {
        console.log(auth_token, auth_username, response.data);

        if (response.data) {
          setTimeout(() => {
            setCookies(auth_token, auth_username);
            setLoggedUser(response.data);
            sessionStorage.setItem('cameFromApp', '1')
          }, 2000);
        }
      });
    }
  }, [auth_token, auth_username]);

  function updateUserProfileInfo({
    user,
    followers,
    following,
    lastTopics,
    collections,
    comments,
  }: IUpdateUserProfileInfo) {
    setUser(user);
    setFollowers(followers);
    setFollowing(following);
    setLastTopics(lastTopics);
    setCollections(collections);
    setComments(comments);
  }

  function signInCheckoutOnePage({ token, user }: SignInCheckoutOnePage): void {
    setState((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        loggedUser: user,
      },
    }));

    const parseToken = token?.split("|")[1];
    setCookies(parseToken, user.username);

    setLoggedUser(user);
    createUtm({ user_id: user.id });
  }

  async function signIn({ email, password }: SignInData) {
    const response = await signInRequest({
      email,
      password,
    });

    const token = response.token;
    const data = response.data;

    if (response.message) {
      return response.message;
    }

    if (response.data) {
      setState((prev) => ({
        ...prev,
        modal: {
          open: false,
        },
      }));

      const parseToken = token?.plainTextToken.split("|")[1];
      setCookies(parseToken, data.username);
      setLoggedUser(response.data);

      if (router?.asPath?.includes("/401")) {
        router?.push("/");
      }
    }
  }

  function signOut() {
    setLoggedUser(null);
    cookie.remove("token");
    cookie.remove("username");
    cookie.remove("statementPage", { path: "/" });
    cookie.remove("statementPage", { path: "/cp" });

    router.reload();
    sessionStorage.removeItem("courses_landing");
  }

  async function signInWithUserToken(token) {
    try {
      const response = await api.post("auth-factory-token", {
        token,
      });

      if (response.data?.data) {
        const parseToken = response.data.token?.plainTextToken.split("|")[1];
        const username = response.data.data.username;

        setCookies(parseToken, username);
        setLoggedUser(response.data?.data);
      }
    } catch (err) {}
  }

  async function register(data: RegisterData) {
    const { email, password, name, ...rest } = data;
    const splitedName = name
      .split(" ")
      .filter((split) => split !== "" && split !== " ");

    const [firstName] = splitedName;
    const lastName = splitedName.length > 1 ? splitedName.reverse()[0] : "";

    const response = await registerRequest({
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      display_name: name,
      ...rest,
    });

    if (response?.type === "success") {
      if (response?.attrs) {
        const { token, user } = response.attrs;

        const parseToken = token?.plainTextToken.split("|")[1];
        setCookies(parseToken, user.username);
        setLoggedUser(user);
        createUtm({ user_id: user.id });
      }

      return response;
    } else {
      return response[Object.keys(response)[0]] || "fail";
    }
  }

  async function updateUser(data: RegisterData) {
    const { name, ...rest } = data;
    const splitedName = name
      .split(" ")
      .filter((split) => split !== "" && split !== " ");

    const [firstName] = splitedName;
    const lastName = splitedName.length > 1 ? splitedName.reverse()[0] : "";

    const response = await updateUserRequest({
      first_name: firstName,
      last_name: lastName,
      display_name: name,
      ...rest,
    });

    if (response?.attrs?.user && response?.type === "success") {
      if (response.attrs) {
        const { user } = response.attrs;

        setLoggedUser(user);
        createUtm({ user_id: user.id });
      }

      return response;
    } else {
      return response[Object.keys(response)[0]] || "fail";
    }
  }

  async function registerWithoutPassword(data: RegisterWithoutPassword) {
    const response = await registerWithoutPasswordRequest(data);

    if (!response?.errors && response?.attrs) {
      if (response.attrs) {
        const { token, user } = response.attrs;

        const parseToken = token?.plainTextToken.split("|")[1];
        setCookies(parseToken, user.username);
        setLoggedUser(user);
        createUtm({ user_id: user.id });
      }

      return "success";
    } else {
      return response[Object.keys(response)[0]] || "fail";
    }
  }

  async function resetPassword({ passwords, token }: ResetPasswordData) {
    try {
      const session = await resetPasswordRequest({ passwords, token });

      if (session.token) {
        setLoggedUser(session.data);
        const parseToken = session.token?.plainTextToken.split("|")[1];

        setCookies(parseToken, session.data.username);

        return "success";
      } else {
        return session;
      }
    } catch (error: any) {
      return error?.response?.data?.message;
    }
  }

  async function firstAccess({
    password,
    confirmPassword,
    token,
  }: FirstAccess) {
    try {
      const session = await firstAccessRequest({
        password,
        confirmPassword,
        token,
      });

      if (session.token) {
        setLoggedUser(session.data);
        const parseToken = session.token?.plainTextToken.split("|")[1];

        setCookies(parseToken, session.data.username);

        return "success";
      } else {
        return session;
      }
    } catch (error: any) {
      return error?.response?.data?.message;
    }
  }

  async function confirmEmail(token: string) {
    try {
      const response = await emailTokenConfirmation({ token });
      if (response) {
        const user = response?.attrs?.user;
        const token = response?.attrs?.token;

        if (user && token) {
          setEmailConfirmed(true);
          const parseToken = token.plainTextToken.split("|")[1];
          setLoggedUser(user);

          cookie.set("token", JSON.stringify(parseToken), { expires: 30 });
          cookie.set("username", JSON.stringify(user?.username), {
            expires: 30,
          });
          return "success";
        }
      }
    } catch (error: any) {
      setEmailConfirmed(false);
      return error?.response?.data?.message;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        register,
        updateUser,
        registerWithoutPassword,
        firstAccess,
        resetPassword,
        confirmEmail,
        setIsProfileOwner,
        updateUserProfileInfo,
        emailConfirmed,
        signInWithUserToken,
        isLoadingUser,
        isAuthenticated,
        isProfileOwner,
        signInCheckoutOnePage,
        loggedUser,
        followers,
        following,
        lastTopics,
        collections,
        comments,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
