import styled from "styled-components";

import { ConsolePageButton } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton";
import { mobileFeatureNotInPlanTemplateBreakpoint } from "pages/[tenant]/console/utils/layoutUtils";

export const ActionButtonContainer = styled(ConsolePageButton)`
  background-color: #605cff;
  color: #f2f2f2;

  position: relative;
  z-index: -1;

  opacity: 1;

  @media (max-width: ${mobileFeatureNotInPlanTemplateBreakpoint}px) {
    margin: 0 auto;
  }
`;
