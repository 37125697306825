import { AccessIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/AccessIcon";
import { AffiliatesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/AffiliatesIcon";
import { CuponsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/CuponsIcon";
import { OffersIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/OffersIcon";
import { OrdersIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/OrdersIcon";
import { SplitsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/SplitsIcon";
import { StatmentIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/StatmentIcon";
import { TransactionsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/TransactionsIcon";
import { TransfersIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/TransfersIcon";
import { IOption, IOptionsHook } from "pages/[tenant]/console/types";

export const useSalesOptions = (): IOptionsHook => {
  const sectionLabel = "Vendas";
  const sales: IOption[] = [
    {
      id: 1,
      label: "Ofertas",
      path: "/console/offers",
      icon: <OffersIcon />,
    },
    {
      id: 2,
      label: "Pedidos",
      path: "/console/orders",
      icon: <OrdersIcon />,
    },
    {
      id: 3,
      label: "Transações",
      path: "/console/transactions",
      icon: <TransactionsIcon />,
    },
    {
      id: 4,
      label: "Acessos vendidos",
      path: "/console/access",
      icon: <AccessIcon />,
    },
    {
      id: 5,
      label: "Cupons",
      path: "/console/coupons",
      icon: <CuponsIcon />,
    },
    {
      id: 6,
      label: "Afiliados",
      path: "/console/affiliates",
      icon: <AffiliatesIcon />,
    },
    {
      id: 7,
      label: "Split de vendas",
      path: "/console/split",
      icon: <SplitsIcon />,
    },
    {
      id: 8,
      label: "Extrato",
      path: "/console/statement",
      icon: <StatmentIcon />,
    },
    {
      id: 9,
      label: "Saques",
      path: "/console/transfers",
      icon: <TransfersIcon />,
    },
  ];

  return {
    options: sales,
    label: sectionLabel,
  };
};
