
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ReactElement, ReactNode, useEffect, useState } from "react";

import type { NextPage } from "next";
import type { AppProps } from "next/app";
import App from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";

import { Spin } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import * as browser from "detect-browser";
import "intl-pluralrules";
import { destroyCookie, setCookie } from "nookies";
import "react-notifications/lib/notifications.css";

import "shared/assets/vendors/flag/sprite-flags-24x24.css";
import "shared/assets/vendors/gaxon/styles.css";
import api from "shared/infra/services/api";
import ErrorHandler from "shared/infra/services/errorHandler";
import fetchMenus from "shared/infra/services/fetchMenus";
import ErrorBoundary from "shared/providers/ErrorBoundary";
import Layout from "shared/providers/Layout";
import { LayoutProvider } from "shared/providers/Layout/context";
import { AuthContextProvider } from "shared/providers/context/AuthContext";
import GlobalContext from "shared/providers/context/GlobalContext";
import GlobalContextType from "shared/providers/context/GlobalContextType";
import initialStates from "shared/providers/context/initialStates";
import { TenantData } from "shared/providers/context/initialStates/tenancy";
import GlobalStyle from "shared/styles/GlobalStyle";
import { cannyConfigs } from "shared/utils/cannyConfig";
import capitalizeFirstLetter from "shared/utils/capitalizeFirstLetter";
import insertCustomScript from "shared/utils/entities/customScripts/insertCustomScript";
import { getCloudEndpoint } from "shared/utils/entities/tenant/getCloudEndpoint";
import getCustomPageTenancy from "shared/utils/entities/tenant/getCustomPageTenancy";
import { getPlatformTenant } from "shared/utils/entities/tenant/getPlatformTenant";
import useIsomorphicLayoutEffect from "shared/utils/hooks/useIsomorphicLayoutEffect";

import * as Sentry from "@sentry/browser";
import { Analytics } from "@vercel/analytics/react";

import AppHead from "./AppHead";

Sentry.init({
  dsn: "https://e3f2eb703dd91d32c2c585ecf3570752@sentry.ensiniodev.com/3",
  sampleRate: 0.1,
});

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  query?: { tenant?: string; isMobileAppClient?: string };
  tenancy: TenantData;
  pageName: string;
  cookies: any;
  customPageTenancy?: {
    image_thumbnail: string;
    title: string;
    seo_title: string;
    seo_description: string;
    seo_keyword: string;
  } | null;
};

type ICustomScriptContent = {
  id: number;
  type: string;
  script: string;
};

type ICustomScript = {
  head: ICustomScriptContent[];
  body: ICustomScriptContent[];
};

function MyApp({
  Component,
  pageProps,
  query,
  tenancy,
  pageName,
  customPageTenancy,
  cookies,
}: AppPropsWithLayout) {
  const router = useRouter();

  const [state, setState] = useState<GlobalContextType>({
    ...initialStates,
  } as GlobalContextType);
  const [customScripts, setCustomScrips] = useState<ICustomScript>(
    {} as ICustomScript,
  );

  const tenantSettings = state.tenancy?.settings;
  const generalTheme = tenantSettings ? tenantSettings["general-theme"] : {};
  const advancedTheme = tenantSettings ? tenantSettings["advanced-theme"] : {};
  const tenantDataExists = Object?.keys(state?.tenancy).length;

  useIsomorphicLayoutEffect(() => {
    const platform = query?.tenant;

    if (platform && platform !== "undefined") {
      setCookie(null, "slug", platform, {
        maxAge: 30 * 24 * 60 * 60,
      });

      if (platform !== "dev") {
        const host = getCloudEndpoint(platform);

        api.defaults.baseURL = `${host}/api/v1`;
      }
    }
  }, [query]);

  useEffect(() => {
    if (api) {
      api.interceptors.response.use(
        (response) => response,
        (error) => {
          ErrorHandler(error);
          return Promise.reject(error);
        },
      );
    }
  }, [api]);

  useEffect(() => {
    const ensinioOldFeaturesPages = [
      "/dashboard",
      "/courses",
      "/media",
      "/social",
      "/blog",
      "/usersCommunity",
      "/bundles",
      "/plans",
      "/collections",
      "/categories",
    ];

    if (!tenancy) return;

    const matchedPages = ensinioOldFeaturesPages.filter((page) =>
      router.route.includes(page),
    );
    const siteSettings = tenancy?.settings?.site;
    const otherSettings = tenancy?.settings?.others;

    const newFeatsIsEnable =
      siteSettings?.ensinioNewFeaturesEnable ||
      otherSettings?.ensinioNewFeaturesEnable;
    if (Number(newFeatsIsEnable) === 1 && matchedPages.length > 0) {
      router.push("/browse");
    }
  }, [router, tenancy]);

  useEffect(() => {
    const uuid = query?.tenant;

    const currentOS = String(browser.detect().os).toLowerCase();
    const deviceIsIOS = currentOS.includes("ios");

    const appModeEnable = cookies?.isMobileAppClient || "0";

    if (appModeEnable === "1") {
      sessionStorage.setItem("appModeEnable", "1");
    } else {
      sessionStorage.removeItem("appModeEnable");
    }

    if (tenancy && uuid) {
      const themeOption =
        tenancy?.settings["general-theme"]?.themeDarkModeOptions;
      fetchMenus().then(({ menus }) => {
        const onlyDarkModeIsEnabled = themeOption === "only_theme_option";
        const onlyLightModeIsEnabled = themeOption === "inactive";
        const bothModesEnabled = themeOption === "active";

        const themeOptionFromLocalStorage = localStorage.getItem(
          "theme_mode",
        ) as any;

        setState((prev) => ({
          ...prev,
          isDevMode: uuid === "dev",
          isMobileAppClient: appModeEnable === "1",
          isIOS: deviceIsIOS,
          tenancy,
          menus,
          theme: {
            value: onlyDarkModeIsEnabled
              ? "dark"
              : onlyLightModeIsEnabled
              ? "light"
              : themeOptionFromLocalStorage || "dark",
            bothModesEnabled,
          },
        }));
      });
    }
  }, [tenancy, query]);

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      destroyCookie(null, "slug");
    });
  }, []);

  useEffect(() => {
    const slug = query?.tenant;
    const getCustomScripts = async (slug) => {
      const endpoint = getCloudEndpoint(slug);
      const response = await axios.get(`${endpoint}/api/v1/custom-script`);

      const head = response.data.filter(
        (data) =>
          data.type.includes("head") &&
          !data.script.includes("dashboard-script"),
      );

      const body = response?.data?.filter((data) =>
        data?.type?.includes("body"),
      );
      body?.forEach(({ script }) => insertCustomScript(script, true));

      setCustomScrips({ head, body });
    };

    if (slug) getCustomScripts(slug);
  }, []);

  useEffect(() => {
    const queries = Object.keys(router.query);

    if (queries.length > 1) {
      const utms = {
        utm_term: router.query.utm_term,
        utm_source: router.query.utm_source,
        utm_medium: router.query.utm_medium,
        utm_campaign: router.query.utm_campaign,
        utm_content: router.query.utm_content,
      };

      const utmsValues = Object.values(utms);
      const hasAtLeastOneUtm = utmsValues.some((utm) => utm);

      if (hasAtLeastOneUtm) {
        sessionStorage.setItem("utms", JSON.stringify(utms));
      }
    }
  }, [router]);

  return (
    <>
      <ErrorBoundary>
        <AppHead
          tenancy={tenancy}
          pageName={pageName}
          query={query}
          customScripts={customScripts}
          customPageTenancy={customPageTenancy}
        />
        <Script id="canny">{cannyConfigs}</Script>
        {customScripts?.body?.map(({ script }) => insertCustomScript(script))}
        <GlobalContext.Provider value={{ state, setState }}>
          <AuthContextProvider>
            <GlobalStyle
              themeMode={state.theme.value as any}
              $generalTheme={generalTheme}
              $advancedTheme={advancedTheme}
            />

            {tenantDataExists ? (
              router.route === "/reset/[token]" ? (
                <Component {...pageProps} />
              ) : (
                <LayoutProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </LayoutProvider>
              )
            ) : (
              <div className="container-loading">
                <Spin />
              </div>
            )}
            <Analytics />
          </AuthContextProvider>
        </GlobalContext.Provider>
      </ErrorBoundary>
    </>
  );
}

MyApp.getInitialProps = async (ctx) => {
  const appProps = await App.getInitialProps(ctx);
  const query = ctx.router.query;
  const platform = query?.tenant;
  const request = ctx?.ctx?.req;

  if (platform && platform !== "undefined") {
    const host = getCloudEndpoint(platform);

    const clientIp = request?.connection?.remoteAddress || "";

    const tenancy = await getPlatformTenant(
      query?.tenant + ".ensinio.com",
      clientIp,
    );

    if (tenancy) api.defaults.baseURL = `${host}/api/v1`;

    const pathname = ctx.router.pathname
      .replace("/[tenant]/", "")
      .split("/")[0];
    const customPageTenancy = await getCustomPageTenancy({ query, pathname });

    return {
      ...appProps,
      query,
      tenancy: tenancy?.data,
      customPageTenancy,
      cookies: request?.cookies,
      pageName: capitalizeFirstLetter(pathname || ""),
    };
  }
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  