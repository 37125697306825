import React, { PropsWithChildren, ReactNode, useEffect } from "react";

import { DisabledPage } from "pages/[tenant]/console/components/templates/DisabledPage";
import { useLayoutOptions } from "pages/[tenant]/console/hooks/useLayoutOptions";

export const ConsoleLayout = ({ children }: PropsWithChildren<ReactNode>) => {
  const {
    pageIsDisabled,
    isNotInUserPlan,
    pageIsHidden,
    TemplateFeatureNotInPlan,
  } = useLayoutOptions();

  useEffect(() => {
    if (pageIsHidden) alert("Página desativada. Insira sua tratativa =)");
  }, [pageIsHidden]);

  if (pageIsDisabled) return <DisabledPage />;
  if (isNotInUserPlan) return <TemplateFeatureNotInPlan />;

  return <>{children}</>;
};
