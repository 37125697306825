import styled from "styled-components";

export const ConsolePageButtonContainer = styled.button`
  all: unset;
  display: block;

  padding: 0.5rem 1rem;
  border-radius: 4px;

  font: 600 0.75rem/1.125rem "Inter";

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  opacity: 0.9;

  transition: 0.3s all;

  white-space: nowrap;

  :not(:disabled) {
    :hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
