import { APIDocIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/APIDocIcon";
import { AllAppsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/AllAppsIcon";
import { AppsInstalledIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/AppsInstalledIcon";
import { CustomScriptIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/CustomScriptIcon";
import { FacebookIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/FacebookIcon";
import { WebhookHistoryIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/WebhookHistoryIcon";
import { WebhooksIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/WebhooksIcon";
import {
  IOption,
  IOptionsHook,
  ISubSection,
} from "pages/[tenant]/console/types";

export const useAppStoreOptions = (): IOptionsHook => {
  const sectionLabel = "App Store";
  const appStore: IOption[] = [
    {
      id: 1,
      label: "Todos os apps",
      path: "/console/apps",
      icon: <AllAppsIcon />,
    },
    {
      id: 2,
      label: "Apps instalados",
      path: "/console/apps/installed",
      icon: <AppsInstalledIcon />,
    },
  ];
  const appStoreSubSections: ISubSection[] = [
    {
      label: "Webhooks",
      options: [
        {
          id: 30,
          label: "Eventos webhooks",
          path: "/console/webhooks",
          icon: <WebhooksIcon />,
        },
        {
          id: 31,
          label: "Históricos de disparos",
          path: "/console/webhooks/history",
          icon: <WebhookHistoryIcon />,
        },
      ],
    },
    {
      label: "Seus Apps",
      options: [
        {
          id: 20,
          label: "Pixel do Facebook",
          path: "/console/apps/installed",
          icon: <FacebookIcon />,
        },
      ],
    },
    {
      label: "Outros",
      options: [
        {
          id: 40,
          label: "Documentação API",
          path: "/console/docsapi",
          icon: <APIDocIcon />,
        },
        {
          id: 41,
          label: "Scripts customizados",
          path: "/console/customscripts",
          icon: <CustomScriptIcon />,
        },
      ],
    },
  ];

  return {
    options: appStore,
    subSections: appStoreSubSections,
    label: sectionLabel,
  };
};
