import { CheckList } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/Checklist";
import { ActionButton as HelpContentActionButton } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/HelpContent/ActionButton";
import { Container as HelpContentContainer } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/HelpContent/Container";
import { Media as HelpContentMedia } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/HelpContent/Media";
import { Title as HelpContentTitle } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/HelpContent/Title";
import { ActionButton } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/TopContent/ActionButton";
import { Description } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/TopContent/Description";
import { Illustration } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/TopContent/Illustration";
import { Title } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/TopContent/Title";
import { TopPageFlag } from "pages/[tenant]/console/components/templates/ConsoleLayout/components/atoms/FeatureNotInPlan/TopFlag";
import { ITemplateFeatureNotInPlanProps } from "pages/[tenant]/console/components/templates/ConsoleLayout/types";

import { TemplateFeatureNotInPlanContainer } from "./styles";

export const TemplateFeatureNotInPlan = ({
  topFlag,
  topContent,
  checkList,
  helpContent,
}: ITemplateFeatureNotInPlanProps) => {
  const hasHelpContentToShow =
    !!helpContent?.title ||
    !!(helpContent?.media?.imageUrl || helpContent?.media?.videoUrl);

  return (
    <div>
      <TopPageFlag href={topFlag.href}>{topFlag.text}</TopPageFlag>

      <TemplateFeatureNotInPlanContainer>
        <div className="top-content">
          <div className="texts">
            <Title>{topContent.title}</Title>
            <Description>{topContent.description}</Description>
            <ActionButton href={topContent.actionButton.href}>
              {topContent.actionButton.text}
            </ActionButton>
          </div>

          <Illustration>{topContent.illustration}</Illustration>
        </div>

        <CheckList list={checkList} />

        {hasHelpContentToShow && (
          <HelpContentContainer>
            <HelpContentTitle>{helpContent.title}</HelpContentTitle>
            <HelpContentActionButton href={helpContent.actionButton.href}>
              {helpContent.actionButton.text}
            </HelpContentActionButton>

            <HelpContentMedia media={helpContent?.media} />
          </HelpContentContainer>
        )}
      </TemplateFeatureNotInPlanContainer>
    </div>
  );
};
