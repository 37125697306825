import { BillboardIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/pages/BillboardIcon";
import { TagsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/pages/TagsIcon";
import { IOptionsHook, ISubSection } from "pages/[tenant]/console/types";

export const useConfigsOptions = (): IOptionsHook => {
  const sectionLabel = "Configurações";
  const optionsSubSections: ISubSection[] = [
    {
      label: "Definições",
      options: [
        {
          id: 1,
          label: "Plataforma",
          path: "/console/testers",
          icon: <TagsIcon />,
        },
        {
          id: 2,
          label: "Aparência e Design",
          path: "/console/design",
          icon: <TagsIcon />,
        },
        {
          id: 3,
          label: "Vendas",
          path: "/console/teste",
          icon: <TagsIcon />,
        },
        {
          id: 4,
          label: "Domínio customizado",
          path: "/console/teste",
          icon: <TagsIcon />,
        },
        {
          id: 5,
          label: "Billboard",
          path: "/console/billboard",
          icon: <TagsIcon />,
        },
        {
          id: 6,
          label: "Menus",
          path: "/console/menus",
          icon: <TagsIcon />,
        },
        {
          id: 7,
          label: "Permissões",
          path: "/console/teste",
          icon: <TagsIcon />,
        },
        {
          id: 8,
          label: "Autenticação",
          path: "/console/teste",
          icon: <TagsIcon />,
        },
        {
          id: 9,
          label: "SEO",
          path: "/console/teste",
          icon: <TagsIcon />,
        },
      ],
    },
    {
      label: "Notificações",
      options: [
        {
          id: 20,
          label: "Templates de notificação",
          path: "/console/teste",
          icon: <BillboardIcon />,
        },
        {
          id: 21,
          label: "Histórico de emails",
          path: "/console/sla ",
          icon: <TagsIcon />,
        },
      ],
    },
  ];

  return {
    label: sectionLabel,
    subSections: optionsSubSections,
  };
};
