import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";

import { ActiveOptionIndicatorContainer } from "./styles";

interface IActiveOptionIndicatorProps {
  activeOptionPosition: number;
}

export const ActiveOptionIndicator = ({
  activeOptionPosition,
}: IActiveOptionIndicatorProps) => {
  const { activeSectionLabel, activeOptionPathInRealTime } =
    useConsolePageContext();

  if (!(activeSectionLabel && activeOptionPathInRealTime)) return null;
  return <ActiveOptionIndicatorContainer position={activeOptionPosition} />;
};
