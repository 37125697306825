import styled from "styled-components";

interface IMobileFooterNavigationContainerProps {
  isSearchEnabled: boolean;
}

export const MobileFooterNavigationContainer = styled.div<IMobileFooterNavigationContainerProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  width: 100%;

  transition: 0.3s all;
  background-color: ${({ theme }) => theme.colors.globalTopbar.solidBackground};
  border-top: 1px solid ${({ theme }) => theme.colors.globalTopbar.borderColor};

  ul {
    display: grid;
    grid-template-columns: repeat(
      ${({ isSearchEnabled }) => (isSearchEnabled ? 5 : 4)},
      1fr
    );
    justify-items: center;

    list-style: none;

    margin: 0;

    li {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      > button {
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: center;
      }
    }

    a,
    button {
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 1.12rem 1.59rem;
      position: relative;
      overflow: hidden;

      ::before {
        content: "";
        background-color: #51515361;
        position: absolute;
        border-radius: 50%;
        transition: 0.3s all ease;
        width: 60px;
        height: 60px;
        opacity: 0;
      }

      :active::before:not(:disabled) {
        opacity: 1;
      }

      :disabled {
        opacity: 0.6;
      }
    }

    a,
    button {
      color: ${({ theme }) => theme.colors.globalTopbar.textColor};
      transition: 0.3s all;

      :hover {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.globalTopbar.textHoverColor};
      }

      svg path {
        transition: 0.3s all;
      }

      &.active {
        svg.menu-icon,
        svg.search-icon {
          path {
            stroke: ${({ theme }) =>
              theme.colors.globalTopbar.bottomIconsActiveColor};
          }
        }

        svg.notifications-icon path {
          fill: ${({ theme }) =>
            theme.colors.globalTopbar.bottomIconsActiveColor};
        }
      }
    }
  }
`;

interface IFullViewMenuProps {
  show: boolean;
}

export const FullViewMenu = styled.div<IFullViewMenuProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  transition: 0.3s all;

  padding: 0 0 5rem 0;

  overflow: scroll;

  background-color: ${({ theme }) => theme.colors.globalTopbar.solidBackground};

  ${({ show }) =>
    show ? "transform: translateY(0)" : "transform: translateY(100%)"};

  > a,
  > button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-top: 1px solid
      ${({ theme }) => theme.colors.globalTopbar.borderColor};
  }

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  a,
  > button {
    padding: 1rem;
    font: 600 1rem/1.3125rem "Inter";
    transition: 0.3s all;

    color: ${({ theme }) => theme.colors.globalTopbar.textColor};

    :hover,
    :focus {
      color: ${({ theme }) => theme.colors.globalTopbar.textHoverColor};
    }
  }

  > button {
    width: 100%;
    border: none;
    background-color: transparent;

    border-top: 1px solid
      ${({ theme }) => theme.colors.globalTopbar.borderColor};

    :hover {
      cursor: pointer;
    }
  }

  svg.user-icon path {
    fill: ${({ theme }) => theme.colors.globalTopbar.textColor};
  }

  li {
    :first-child {
      border-top: 1px solid
        ${({ theme }) => theme.colors.globalTopbar.borderColor};
    }

    & + li {
      border-top: 1px solid
        ${({ theme }) => theme.colors.globalTopbar.borderColor};
    }
  }
`;
