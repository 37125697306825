import { AnalyticsSalesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/AnalyticsSalesIcon";
import { CertificatesIssuedIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/CertificatesIssuedIcon";
import { ChatIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/ChatIcon";
import { ConcludedMissionsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/ConcludedMissionsIcon";
import { DiscutionsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/DiscutionsIcon";
import { EngagementIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/EngagementIcon";
import { ExportsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/ExportsIcon";
import { LearningIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/LearningIcon";
import { ProgressIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/ProgressIcon";
import { QuizzAnswersIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/QuizzAnswersIcon";
import { IOption, IOptionsHook } from "pages/[tenant]/console/types";

export const useAnalyticsOptions = (): IOptionsHook => {
  const sectionLabel = "Relatórios";
  const analytics: IOption[] = [
    {
      id: 1,
      label: "Engajamento",
      path: "/console/dashboards/engagement",
      icon: <EngagementIcon />,
      shouldHide: true,
    },
    {
      id: 2,
      label: "Vendas",
      path: "/console/dashboards/sales",
      icon: <AnalyticsSalesIcon />,
      isNotInUserPlan: true,
    },
    {
      id: 3,
      label: "Aprendizado",
      path: "/console/dashboards/learning",
      icon: <LearningIcon />,
    },
    {
      id: 4,
      label: "Análise de progresso",
      path: "/console/progress",
      icon: <ProgressIcon />,
      isNotInUserPlan: true,
    },
    {
      id: 5,
      label: "Exportações",
      path: "/console/reports",
      icon: <ExportsIcon />,
    },
    {
      id: 6,
      label: "Comentários",
      path: "/console/progress",
      icon: <ChatIcon />,
    },
    {
      id: 7,
      label: "Respostas de Quizzes",
      path: "/console/progress",
      icon: <QuizzAnswersIcon />,
    },
    {
      id: 8,
      label: "Discussões",
      path: "/console/progress",
      icon: <DiscutionsIcon />,
    },
    {
      id: 9,
      label: "Certificados Emitidos",
      path: "/console/progress",
      icon: <CertificatesIssuedIcon />,
    },
    {
      id: 10,
      label: "Missões concluídas",
      path: "/console/progress",
      icon: <ConcludedMissionsIcon />,
    },
  ];

  return {
    options: analytics,
    label: sectionLabel,
  };
};
